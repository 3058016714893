import { ExclamationCircleFilled } from "@ant-design/icons";
import {
  SubscriptionPlanType,
  SubscriptionScheduledChangeType,
  SubscriptionUser,
} from "@coeff/api";
import { Avatar } from "antd";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";

import {
  Alert,
  ConfirmBox,
  FlexColumn,
  Typography,
  Button,
  FlexRow,
  CardIcon,
  BillingUpgradeToProIcon,
  BillingManageLicensesUserIcon,
  UserList,
} from "../../../components";
import { AVATAR_COLORS as avatarColors, COLORS } from "../../../constants";
import { BContext } from "../../../types";
import {
  planNameFromType,
  useTrack,
  numberWithCommas,
  getIsGmailLikeDomainFromEmail,
  pluralize,
} from "../../../utils";
import { BillingContext } from "../Billing";
import { HelpContent } from "../components/HelpContent";

const Container = styled.div`
  color: ${COLORS.black85};
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
`;

const SubscriptionInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 60%;
`;

const HelpContainer = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const AlertMsg = styled(Alert)`
  margin-bottom: 8px;
  border: none;

  &.ant-alert-error {
    .updatePaymentLink {
      position: absolute;
      right: 16px;
      top: 25px;
      font-weight: bold;
    }
  }

  &.ant-alert-success {
    font-weight: 600;
    background-color: ${COLORS.seafoamBg};

    .ant-alert-message {
      color: ${COLORS.seafoam};
    }
  }
`;

const Section = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  margin-bottom: 20px;

  .sectionTitle {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 4px;
  }

  .content {
    color: ${COLORS.black65};
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: ${COLORS.black2};

    padding: 16px;
    border-radius: 5px;

    &.noPadding {
      padding: 0px;
    }

    .ant-progress-inner {
      background: ${COLORS.black15};
    }
  }
`;

const ManageUserContainer = styled(FlexRow)`
  justify-content: space-between;
  flex: 1;
  background: white;
  border-radius: 5px;
`;

const LinkTypograhy = styled(Typography)`
  &:hover {
    color: ${COLORS.coeblue4};
  }
`;

const StackedAvatars = styled.div`
  display: flex;

  list-style-type: none;
  margin: auto;
  padding: 0px;
  flex-direction: row;
  margin-left: 16px;
`;

export const SubscriptionInfoV2 = () => {
  const track = useTrack();

  const {
    basePath,
    currentUserEmail,
    userSubscription,
    plan_type,
    enableSubscriptionUpdate,
    due_invoices_count,
    due_invoice_error_type,
    usersInCurrentPlan,
    currentUserPlanManager,
    setContactSupportInfoBox,
  } = useContext(BillingContext) as BContext;

  useEffect(() => {
    track("billing_subscription_viewed");
  }, []);

  const history = useHistory();

  const [showManagerPrompt, setShowManagerPrompt] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Plans & Billing - Coefficient Workspace";
  }, []);

  const subscriptionDetails = userSubscription?.subscription_details || null;

  const planType = subscriptionDetails?.plan_type || "";

  useEffect(() => {
    if (!userSubscription) {
      return history.push({
        pathname: `${basePath}/plans`,
        search: window.location.search,
      });
    }
  }, [planType]);

  const planName = planNameFromType(planType || "");

  const nextBillingCycleDateStr = subscriptionDetails
    ? moment(subscriptionDetails?.next_billing_cycle_dt).format("MMM DD, YYYY")
    : undefined;

  const firstInvoiceDateStr = subscriptionDetails?.subscription_active_since_dt
    ? moment(subscriptionDetails?.subscription_active_since_dt).format("MMM DD, YYYY")
    : undefined;

  const handleChangePlan = () => {
    if (!enableSubscriptionUpdate) {
      setContactSupportInfoBox({ title: "Change Plan" });
      return;
    }
    if (currentUserPlanManager?.email !== currentUserEmail) {
      setShowManagerPrompt(true);
      return;
    }

    const searchParams = new URLSearchParams(window.location.search);

    searchParams.set("from", "billing");

    history.push({
      pathname: `${basePath}/plans`,
      search: searchParams.toString(),
    });
  };

  const handlePaymentAndBilling = (e: React.MouseEvent) => {
    track("billing_settings_clicked");
    const searchParams = new URLSearchParams(window.location.search);
    if (currentUserPlanManager?.email !== currentUserEmail) {
      e.preventDefault();
      setShowManagerPrompt(true);
      return;
    }

    history.push({
      pathname: `${basePath}/billing`,
      search: searchParams.toString(),
    });
  };

  const handleManageTeam = (e: React.MouseEvent) => {
    const searchParams = new URLSearchParams(window.location.search);

    track("billing_manage_team_clicked");
    if (currentUserPlanManager?.email !== currentUserEmail) {
      e.preventDefault();
      setShowManagerPrompt(true);
      return;
    }

    history.push({
      pathname: `${basePath}/team/manage`,
      search: searchParams.toString(),
    });
  };

  const maxUsersCount = subscriptionDetails?.max_users ?? 0;

  const isPlanOwner = currentUserPlanManager?.email === currentUserEmail;

  const ownerHasSeatOnPlan = Boolean(
    usersInCurrentPlan.find(user => user.user.email === currentUserPlanManager?.email)
  );

  const isPlanOwnerWithoutLicenseOnPlan = isPlanOwner && !ownerHasSeatOnPlan;

  let sortedUsersInPlan = [...usersInCurrentPlan, ...(userSubscription?.invite_users ?? [])].sort(
    (a, b) => {
      const bName = `${b.user.first_name} ${b.user.last_name}`;
      const aName = `${a.user.first_name} ${a.user.last_name}`;

      if (aName < bName) {
        return -1;
      }
      if (aName > bName) {
        return 1;
      }
      return 0;
    }
  );

  const currentPlanManager = sortedUsersInPlan.find(
    u => u.user.user_id === currentUserPlanManager?.user_id
  );

  if (currentPlanManager) {
    sortedUsersInPlan = sortedUsersInPlan.filter(
      u => u.user.user_id !== currentPlanManager?.user.user_id
    );
    sortedUsersInPlan.unshift(currentPlanManager);
  }

  const renderUserRole = (user: SubscriptionUser) => {
    let role = "";

    if (user.user_id === currentPlanManager?.user.user_id) {
      role = "Plan Owner";
    }

    return (
      <Typography color="textSecondary" fontWeight={500}>
        {role}
      </Typography>
    );
  };

  const PlanOwerSubsciptionPlanText = isPlanOwnerWithoutLicenseOnPlan
    ? `You're the owner of this ${planName} plan`
    : `You're on the ${planName} plan`;

  return (
    <Container>
      <Typography fontSize="26px" fontWeight={700} style={{ marginBottom: "52px" }}>
        Plans & Billing
      </Typography>

      <Content>
        <SubscriptionInfoContainer>
          {isPlanOwner ? (
            <>
              <Section>
                {isPlanOwnerWithoutLicenseOnPlan && (
                  <AlertMsg
                    type="info"
                    style={{ padding: "12px 16px", marginBottom: "16px" }}
                    description={
                      <FlexColumn gap={0}>
                        <Typography fontWeight={600}>
                          {`You're on the ${planNameFromType(plan_type)} plan`}
                        </Typography>
                        <Typography color="textSecondary">
                          {`Add yourself to your ${planName} plan to get the most out of Coefficient`}
                        </Typography>
                      </FlexColumn>
                    }
                  />
                )}

                <div className="sectionTitle">Subscription Plan</div>

                {subscriptionDetails?.has_scheduled_changes &&
                  subscriptionDetails?.scheduled_change_type === "downgrade_to_starter" && (
                    <AlertMsg
                      type="warning"
                      description={
                        <Typography fontWeight={600}>
                          Your plan will be downgraded to Starter on{" "}
                          {moment(subscriptionDetails?.next_billing_cycle_dt)
                            .add(1, "day")
                            .format("MMM DD, YYYY")}
                        </Typography>
                      }
                    />
                  )}
                <div className="content">
                  <FlexColumn gap={2} style={{ width: "100%" }}>
                    <FlexRow style={{ justifyContent: "space-between", flex: 1 }}>
                      <FlexColumn gap={0}>
                        <Typography color="textPrimary" variant="body2" fontWeight={600}>
                          {PlanOwerSubsciptionPlanText}
                        </Typography>
                        {firstInvoiceDateStr && (
                          <Typography
                            color="inherit"
                            variant="body2"
                          >{`Since ${firstInvoiceDateStr}`}</Typography>
                        )}
                      </FlexColumn>

                      <Button type="link" className="light" onClick={handleChangePlan}>
                        Change plan
                      </Button>
                    </FlexRow>
                    <ManageUserContainer>
                      <FlexRow gap={2} style={{ padding: "16px" }}>
                        <BillingManageLicensesUserIcon />
                        <FlexColumn style={{ width: 200 }} gap={0}>
                          <Typography fontWeight={700} color="inherit">
                            {subscriptionDetails?.plan_type === "starter"
                              ? `Add more users to your plan`
                              : `${maxUsersCount} user license${pluralize(
                                  maxUsersCount
                                )} on your plan`}
                          </Typography>
                        </FlexColumn>
                      </FlexRow>
                      {subscriptionDetails?.plan_type === "starter" ? (
                        <Button
                          type="primary"
                          onClick={handleChangePlan}
                          style={{ marginRight: "16px" }}
                        >
                          <BillingUpgradeToProIcon />
                          Upgrade to Pro
                        </Button>
                      ) : (
                        <Button type="link" onClick={handleManageTeam}>
                          Manage licenses
                        </Button>
                      )}
                    </ManageUserContainer>
                  </FlexColumn>
                </div>
              </Section>
              <Section>
                <div className="sectionTitle">Payment & Billing</div>
                {!!due_invoices_count &&
                  due_invoices_count > 0 &&
                  due_invoice_error_type === "no_payment_method" && (
                    <AlertMsg
                      message="Please add a payment method"
                      description={
                        <>
                          Your subscription is not active until you add a payment method.
                          <Link
                            className="updatePaymentLink"
                            to={{ pathname: `${basePath}/billing`, search: window.location.search }}
                            onClick={e => {
                              if (currentUserPlanManager?.email !== currentUserEmail) {
                                e.preventDefault();
                                setShowManagerPrompt(true);
                              }
                            }}
                          >
                            Add Payment Method
                          </Link>
                        </>
                      }
                      type="error"
                      showIcon
                      icon={<ExclamationCircleFilled />}
                    />
                  )}
                {!!due_invoices_count &&
                  due_invoices_count > 0 &&
                  due_invoice_error_type === "other" && (
                    <AlertMsg
                      message="Payment failed"
                      description={
                        <>
                          Your last payment of {subscriptionDetails?.recurring_amount_usd} couldn't
                          be processed to the card ending with ...
                          {subscriptionDetails?.cc_last_4_digits}
                          <Link
                            className="updatePaymentLink"
                            to={{ pathname: `${basePath}/billing`, search: window.location.search }}
                            onClick={e => {
                              if (currentUserPlanManager?.email !== currentUserEmail) {
                                e.preventDefault();
                                setShowManagerPrompt(true);
                              }
                            }}
                          >
                            Update Payment Method
                          </Link>
                        </>
                      }
                      type="error"
                      showIcon
                      icon={<ExclamationCircleFilled />}
                    />
                  )}
                {!!due_invoices_count &&
                  due_invoices_count > 0 &&
                  due_invoice_error_type === "no_payment_method" && (
                    <AlertMsg
                      message="Please add a payment method"
                      description={
                        <>
                          Your subscription is not active until you add a payment method.
                          <Link
                            className="updatePaymentLink"
                            to={{ pathname: `${basePath}/billing`, search: window.location.search }}
                            onClick={e => {
                              if (currentUserPlanManager?.email !== currentUserEmail) {
                                e.preventDefault();
                                setShowManagerPrompt(true);
                              }
                            }}
                          >
                            Add Payment Method
                          </Link>
                        </>
                      }
                      type="error"
                      showIcon
                      icon={<ExclamationCircleFilled />}
                    />
                  )}
                {subscriptionDetails?.has_scheduled_changes &&
                subscriptionDetails.scheduled_change_type ===
                  SubscriptionScheduledChangeType.DowngradeToMonthlyBilling ? (
                  <AlertMsg
                    type="warning"
                    message={
                      <Typography fontWeight={600}>
                        Your billing frequency will be changed to monthly starting on{" "}
                        {moment(subscriptionDetails?.next_billing_cycle_dt)
                          .add(1, "day")
                          .format("MMM DD, YYYY")}
                      </Typography>
                    }
                  />
                ) : null}
                {!!due_invoices_count &&
                  due_invoices_count > 0 &&
                  due_invoice_error_type === "pending_offline_payment" && (
                    <AlertMsg
                      message="Unpaid invoice"
                      description={
                        <>
                          Your last invoice has not been marked as paid yet. Please contact support
                          to resolve this.
                          <a
                            href="mailto:support@coefficient.io"
                            className="updatePaymentLink"
                            target="_blank"
                          >
                            Contact Support
                          </a>
                        </>
                      }
                      type="error"
                      showIcon
                      icon={<ExclamationCircleFilled />}
                    />
                  )}
                <div className="content">
                  <FlexColumn gap={0}>
                    <Typography color="inherit" variant="body2">{`You are paying $${
                      numberWithCommas(subscriptionDetails?.current_paying_amount_usd ?? 0) ?? "N/A"
                    } per ${
                      subscriptionDetails?.billing_interval === "monthly" ? "month" : "year"
                    }`}</Typography>
                    {firstInvoiceDateStr && (
                      <Typography
                        color="inherit"
                        variant="body2"
                      >{`Next payment ${nextBillingCycleDateStr} (${subscriptionDetails?.billing_interval})`}</Typography>
                    )}

                    {subscriptionDetails?.cc_type && (
                      <FlexRow gap={1} style={{ marginTop: "24px" }}>
                        <CardIcon type={subscriptionDetails?.cc_type || ""} />{" "}
                        {subscriptionDetails.cc_owner_name ? (
                          <Typography style={{ wordBreak: "break-all" }} lineClamp={1}>
                            {subscriptionDetails?.cc_owner_name}
                          </Typography>
                        ) : null}
                        <Typography style={{ marginRight: 8 }}>
                          (...{subscriptionDetails?.cc_last_4_digits})
                        </Typography>
                      </FlexRow>
                    )}
                  </FlexColumn>
                  <Button type="link" onClick={handlePaymentAndBilling}>
                    Manage billing
                  </Button>
                </div>
              </Section>
            </>
          ) : (
            <>
              <Section>
                <div className="sectionTitle">Subscription Plan</div>

                <div className="content">
                  <FlexColumn gap={2} style={{ width: "100%" }}>
                    <FlexRow style={{ justifyContent: "space-between", flex: 1 }}>
                      <FlexColumn gap={0}>
                        <Typography
                          color="textPrimary"
                          fontWeight={600}
                          variant="body2"
                        >{`You're on the ${planName} plan`}</Typography>
                        {firstInvoiceDateStr && (
                          <Typography
                            color="inherit"
                            variant="body2"
                          >{`Since ${firstInvoiceDateStr}`}</Typography>
                        )}
                        <Typography
                          color="inherit"
                          variant="body2"
                        >{`Plan managed by ${currentUserPlanManager?.first_name} ${currentUserPlanManager?.last_name}`}</Typography>
                      </FlexColumn>
                    </FlexRow>
                  </FlexColumn>
                </div>
              </Section>
              <Section>
                <div className="sectionTitle">{`${sortedUsersInPlan.length} user${pluralize(
                  sortedUsersInPlan.length
                )} on your ${planName} plan`}</div>

                <FlexColumn gap={0}>
                  {sortedUsersInPlan.map((user, i) => (
                    <UserList
                      key={i}
                      avatarColor={avatarColors[i % avatarColors.length]}
                      name={`${user.user?.first_name || user.user.email} ${
                        user.user?.last_name || ""
                      }`}
                      email={user.user?.email || ""}
                      userId={user.user?.user_id || ""}
                      plan={user.plan_type}
                      footer={
                        <FlexRow gap={2} style={{ justifyContent: "flex-end" }}>
                          <FlexRow gap={2} style={{ justifyContent: "space-between" }}>
                            {renderUserRole(user.user)}
                          </FlexRow>
                        </FlexRow>
                      }
                    />
                  ))}
                </FlexColumn>
              </Section>
            </>
          )}
        </SubscriptionInfoContainer>

        <HelpContent />
      </Content>

      <ConfirmBox
        title={
          currentUserPlanManager
            ? `${planNameFromType(subscriptionDetails?.plan_type ?? "")} plan managed by ${
                currentUserPlanManager?.first_name
              } ${currentUserPlanManager?.last_name}`
            : "Please assign at least one user"
        }
        visible={showManagerPrompt}
        onOk={() => setShowManagerPrompt(false)}
        onCancel={() => setShowManagerPrompt(false)}
        okText="Got it"
        width={340}
      >
        {currentUserPlanManager
          ? `Please reach out to ${
              currentUserPlanManager?.first_name ?? ""
            } to make any changes to the
        plan or to add/remove user licenses.`
          : "Please assign at least one user before modifying the plan and billing settings"}
      </ConfirmBox>
    </Container>
  );
};
